<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>

    <div style="background-color: #fafafa"  v-loading="loading">
      <div class="max_width min_width">
        <div
          class="flex align_center padtb_20"
          :style="{ 'margin-top': offsetHeight + 'px' }"
        >
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/feed' }"
              >供方市场</el-breadcrumb-item
            >
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618"
              >发布产品</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div style="background-color: #fafafa; padding-bottom: 30px;" >
      <div class="max_width min_width">
        <section class="bgfff">
          <div class="dis align_center">
            <div class="icon"></div>
            <div class="size_18 marl_20">添加产品</div>
          </div>
        </section>

        <div class="mart_20">
          <el-form
            :label-position="labelPosition"
            label-width="180px"
            :model="formLabelAlign"
            :rules="rules"
            ref="formLabelAlign"
          >
            <el-form-item label="供给地区" prop="city_id">
              <el-select
                v-model="formLabelAlign.city_id"
                placeholder="请选择供给地区"
                class="width_500"
              >
                <el-option
                  v-for="item in city"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品名称" prop="product_name">
              <el-input
                class="width_500"
                placeholder="请输入产品名称"
                v-model="formLabelAlign.product_name"
              ></el-input>
            </el-form-item>
            <el-form-item label="行业类型" prop="industry_id">
              <!-- <el-input
                class="width_500"
                v-model="formLabelAlign.industry_id"
              ></el-input> -->
              <el-select
                class="width_500"
                v-model="formLabelAlign.industry_id"
                placeholder="请选择所属分类"
              >
                <el-option
                  v-for="item in pattern"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品规格" prop="model_number">
              <el-input
                class="width_500"
                placeholder="请输入产品规格"
                v-model="formLabelAlign.model_number"
              ></el-input>
            </el-form-item>
            <el-form-item label="数量" prop="num">
              <el-input
                placeholder="请输入数量"
                class="width_500"
                v-model="formLabelAlign.num"
              ></el-input>
            </el-form-item>
            <el-form-item label="单价" prop="price">
              <el-input
                placeholder="请输入单价"
                class="width_500"
                v-model="formLabelAlign.price"
              ></el-input>
            </el-form-item>
            <el-form-item label="供货时限" prop="days">
              <el-input
                placeholder="请输入供货时限"
                class="width_500"
                v-model="formLabelAlign.days"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系人" prop="contacts">
              <el-input
                placeholder="请输入联系人"
                class="width_500"
                v-model="formLabelAlign.contacts"
              ></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="phone">
              <el-input
                class="width_500"
                placeholder="请输入联系电话"
                v-model="formLabelAlign.phone"
              ></el-input>
            </el-form-item>

            <el-form-item label="产品图片 " required>
              <el-upload ref="uploadfiles2" list-type="picture-card" class="avatar-uploader" action="#"
                accept=".jpg,.jpeg,.png" :multiple="true" :limit="1" name="file2" :auto-upload="true"
                :http-request="httpreq1" :before-upload="changeUpload2" :on-exceed="handleExceed"
                :file-list="uploadList"
                :on-remove="handleRemove2">

                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>

            <div class="width_50">
              <div class="flex">
                <div class="business left">服务内容</div>
              </div>
              <div class="mat_30 left"></div>
            </div>

            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 500px; overflow-y: hidden"
                v-model="html"
                :defaultConfig="editorConfig"
                :mode="mode"
                id="#editor"
                @onCreated="onCreated"
              />
            </div>

            <!-- 服务说明协议 -->
            <app-serve
              @handleChange="handleChange"
              :checkeding="checkeding"
            ></app-serve>
            <el-button
              @click="addfeed()"
              style="
                background-color: #ff7129;
                color: #fefefe;
                margin-top: 30px;
              "
              >确认添加</el-button
            >

            <el-button
              style="
                background-color: #cfcfcf;
                color: #fefefe;
                margin-top: 30px;
              "
              @click="balck()"
              >取消</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>

</template>
<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
import Servetext from "@/components/servetext/servetext.vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  data() {
    return {
      labelPosition: "left",
      offsetHeight: "",
      editor: null,
      checkeding: false,
      city: [],
      loads:[],
      img:"",
      uploadList:[],
      html: "",
      loading:false,
      pattern: [
        { value: 1, label: "盐湖化工" },
        { value: 2, label: "新能源" },
        { value: 3, label: "新材料" },
        { value: 4, label: "冶金产业" },
        { value: 5, label: "绿色建材" },
        { value: 6, label: "装备制造" },
        { value: 7, label: "信息技术" },
        { value: 8, label: "节能环保" },
        { value: 9, label: "高原特色" },
        { value: 10, label: "其他" },
      ],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            // server: "http://jxx.zhenchangkj.com/v1/upload_editor",
            server: "https://www.smeqh.cn/v1/upload_editor",

            allowedFileTypes: ["image/*"],
            maxNumberOfFiles: 1,
          },
        },
      },
      toolbarConfig: {},
      formLabelAlign: {
        city_id: "",
        industry_id: "",
        product_name: "",
        model_number: "",
        num: "",
        
        price: "",
        days: "",
        contacts: "",
        phone: "",
        content: "",
      },
      mode: "default", // or 'simple'
      rules: {
        city_id: [
          { required: true, message: "请选择供给地区", trigger: "blur" },
          { required: true, message: "供给地区不能为空", trigger: "change" },
        ],
        product_name: [
          { required: true, message: "请输入产品名称", trigger: "blur" },
          { required: true, message: "产品名称不能为空", trigger: "change" },
        ],
        industry_id: [{ required: true, message: "请选择所属分类" }],
        model_number: [
          { required: true, message: "请输入产品规格", trigger: "blur" },
          { required: true, message: "产品规格不能为空", trigger: "change" },
        ],
        num: [
          { required: true, message: "请输入数量", trigger: "blur" },
          { required: true, message: "数量不能为空", trigger: "change" },
        ],
        price: [
          { required: true, message: "请输入单价", trigger: "blur" },
          { required: true, message: "单价不能为空", trigger: "change" },
        ],
        days: [
          { required: true, message: "请输入供货时限", trigger: "blur" },
          { required: true, message: "供货时限不能为空", trigger: "change" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { required: true, message: "联系人不能为空", trigger: "change" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { required: true, message: "联系电话不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getcity();
  },
  methods: {
    //服务说明
    handleChange(item) {
      this.checkeding = item;
    },
    balck() {
      this.$router.push("/demand");
    },
    //获取高度
    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },
    //富文本
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },

    


    // 添加产品
    addfeed() {
      if (this.checkeding === false) {
        this.$message({
          message: "请阅读服务说明",
          type: "warning",
          duration: 1500,
        });
      }
      this.$refs["formLabelAlign"].validate((valid) => {
        if (valid) {
          this.loading = true
          let formdata = new FormData();
      formdata.append("file", this.loads);
      this.$post("upload_img", formdata).then((res) => {
        this.img = res.address;
        this.supply_add()
        console.log(res, "res");
      });

          
        }
      });
    },

    supply_add(){
      let data = {
            token: localStorage.eleToken,
            city_id: this.formLabelAlign.city_id,
            industry_id: this.formLabelAlign.industry_id,
            product_name: this.formLabelAlign.product_name,
            model_number: this.formLabelAlign.model_number,
            num: this.formLabelAlign.num,
            price: this.formLabelAlign.price,
            days: this.formLabelAlign.days,
            contacts: this.formLabelAlign.contacts,
            phone: this.formLabelAlign.phone,
            content: this.html,
            supply_img:this.img
          };
          console.log(data, "data");
          this.$post("supply_add", data).then((res) => {
            console.log(res, "res");
            this.loading = false
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
            });
            
            this.$router.push("/feed");
          });

    },
  

    // 获取城市
    getcity() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("area_select", data).then((res) => {
        console.log(res, "re789s");
        this.city = res.result;
        console.log(this.city, "this.city");
      });
    },

    handleRemove2() {
      this.documentList2 = [];
      this.fileObj2 = {};

      this.percent2 = 0;

      this.imgFlag2 = false;
    },

      //上传图片
      httpreq1(load) {

        this.loads = load.file
      console.log(this.loads, 'loads')
    
    },

    handleExceed() {
      this.$message({
        message: "最大上传数量为1张!",
        type: "warning",
        duration: 1500,
      });
    },

    changeUpload2(file, fileList) {
      let testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "jpg";
      const extension1 = testmsg === "jpeg";
      const extension2 = testmsg === "png";

      if (!extension && !extension1 && !extension2) {
        this.$message({
          message: "上传文件只能是.jpg、.jpeg、.png格式!",
          type: "warning",
          duration: 1500,
        });
        this.uploadList = []

        return false;
      }
      let formData = new FormData();

      formData.append("file", file.raw);

      // this.$post("upload_file", formData).then((res) => {
      //   this.finance_img = res.data.address;
      //   this.dialogVisible = false;

      //   let picObj = { url: this.finance_img };
      //   this.uploadList2.push(picObj);
      // });
    },
  },
  components: {
    "app-header": Header,
    "app-footer": Footer,
    "app-serve": Servetext,
    Editor,
    Toolbar,
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.icon {
  width: 4px;
  height: 19px;
  background: #fb1010;
  border-radius: 2px;
}
.width_500 {
  width: 500px;
}
</style>