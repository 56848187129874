import { render, staticRenderFns } from "./releasefeed.vue?vue&type=template&id=76576d90&scoped=true&"
import script from "./releasefeed.vue?vue&type=script&lang=js&"
export * from "./releasefeed.vue?vue&type=script&lang=js&"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&lang=css&"
import style1 from "./releasefeed.vue?vue&type=style&index=1&id=76576d90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76576d90",
  null
  
)

export default component.exports